import React from "react";
import "./Newsletter.less";
import PageWidth from "../../organisms/PageWidth/PageWidth";
import Section from "../../organisms/Section/Section";
import Button from "../../atoms/Button/Button";

import addToMailchimp from "gatsby-plugin-mailchimp";
import Input from "../../atoms/Input/Input";
import { validateEmail } from "../../../utils/validation";
import { Link } from "gatsby";

const Newsletter = () => {
  const [formState, setFormState] = React.useState("form");
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");

  const onChange = e => {
    if (e.target.name === "name") {
      setName(e.target.value);
    } else if (e.target.name === "email") {
      setEmail(e.target.value);
    }
  };

  const onSubmit = () => {
    setFormState("loading");
    const listFields = {
      FNAME: name,
      PATHNAME: window.location.pathname
    };
    addToMailchimp(email, listFields)
      .then(() => {
        setFormState("done");
      })
      .catch(() => {});
  };

  let isValid = false;
  if (name.length > 2 && validateEmail(email)) {
    isValid = true;
  }

  let renderForm = null;
  switch (formState) {
    default:
    case "form":
      renderForm = (
        <div className="text-max-line">
          <h3>Bock auf Bienen?</h3>
          <p>
            In meinem Newsletter informiere ich Dich,{" "}
            <strong>wie es den Bienen geht</strong> und wenn eine{" "}
            <strong>neue Ernte</strong> kommt.
          </p>
          <Input
            name="name"
            value={name}
            onChange={e => onChange(e)}
            placeholder="Dein Vorname"
          />
          <Input
            name="email"
            value={email}
            onChange={e => onChange(e)}
            placeholder="Deine E-Mail Adresse"
          />
          <br />
          <Button
            variant="secondary"
            onClick={() => onSubmit()}
            disabled={!isValid}
          >
            Newsletter abonnieren
          </Button>
          <p className="newsletter__legal small-print text-max-line">
            Ich sende nur E-Mails bei neuen Ernten und etwa 3-6 mal im Jahr zu
            den Völkern. Deine Daten werden nicht weiter gegeben. Mehr Infos
            unter <Link to="/datenschutz">Datenschutz</Link>.
          </p>
        </div>
      );
      break;
    case "loading":
      renderForm = (
        <div className="text-max-line">
          <h3>Sssss ssss sssssum...</h3>
          <p>
            Die Post-Biene ist gerade gestartet, um Deine Daten zu übermitteln.
            Sie kommt gleich zurück...
          </p>
        </div>
      );
      break;
    case "done":
      renderForm = (
        <div className="text-max-line">
          <h3>Fast erledigt...</h3>
          <p>
            Du hast gerade eine E-Mail erhalten. Bitte klick' auf den Knopf
            darin, um Deine E-Mail Adresse zu bestätigen.
          </p>
        </div>
      );
      break;
  }

  return (
    <Section background="green">
      <PageWidth>
        <div className="newsletter">{renderForm}</div>
      </PageWidth>
    </Section>
  );
};

export default Newsletter;

import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import "./Button.less";

const Button = ({
  variant,
  asLink,
  onClick,
  children,
  className = "",
  disabled
}) => {
  const classNames = ["button", className];
  switch (variant) {
    default:
    case "primary":
      classNames.push("button--primary");
      break;
    case "secondary":
      classNames.push("button--secondary");
      break;
    case "tertiary":
      classNames.push("button--tertiary");
      break;
  }

  if (asLink) {
    return (
      <Link to={onClick} className={classNames.join(" ")}>
        {children}
      </Link>
    );
  }

  let optionalProps = { disabled: undefined };
  if (disabled) {
    optionalProps.disabled = "disabled";
  }

  return (
    <button
      onClick={onClick}
      className={classNames.join(" ")}
      {...optionalProps}
    >
      {children}
    </button>
  );
};

Button.protoTypes = {
  asLink: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.oneOf(["primary", "secondary", "tertiary"])
};

export default Button;

import React from "react";
import "./Header.less";
import PageWidth from "../../organisms/PageWidth/PageWidth";
import Section from "../../organisms/Section/Section";
import { Link } from "gatsby";

const Navigation = () => {
  return (
    <Section className="header">
      <PageWidth>
        <Link className="header__link" to="/">
          stadthonig<span className="header__tld">.shop</span>
        </Link>
        <div className="header__news">Demnächst neuer Honig aus Hamburg!</div>
      </PageWidth>
    </Section>
  );
};

export default Navigation;

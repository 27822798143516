import { Link } from "gatsby";
import React from "react";
import "./Footer.less";
import PageWidth from "../../organisms/PageWidth/PageWidth";
import Section from "../../organisms/Section/Section";
import Newsletter from "../../molecules/Newsletter/Newsletter";

import MasterCard from "../../../assets/svg/payment/MasterCard.svg";
import VisaCard from "../../../assets/svg/payment/Visa.svg";
import AmericanExpress from "../../../assets/svg/payment/AmericanExpress.svg";
import ApplePay from "../../../assets/svg/payment/ApplePay.svg";
import GooglePay from "../../../assets/svg/payment/GooglePay.svg";
import SEPA from "../../../assets/svg/payment/SEPA.svg";
import { MIN_CART_VALUE } from "../../../const/cart";

const Footer = () => {
  return (
    <div className="footer">
      <Newsletter />
      <Section background="black" className="footer__info">
        <PageWidth>
          <div className="footer__payment">
            <span
              className="footer__payment-method"
              title="Zahle mit Master Card"
            >
              <MasterCard />
            </span>
            <span className="footer__payment-method" title="Zahle mit Visa">
              <VisaCard />
            </span>
            <span
              className="footer__payment-method"
              title="Zahle mit American Express"
            >
              <AmericanExpress />
            </span>
            <span
              className="footer__payment-method"
              title="Zahle mit Apple Pay"
            >
              <ApplePay />
            </span>
            <span
              className="footer__payment-method"
              title="Zahle mit Google Pay"
            >
              <GooglePay />
            </span>
            <span
              className="footer__payment-method"
              title="Zahle mit S€PA Lastschrift"
              style={{ display: "none" }}
            >
              <SEPA />
            </span>
            <p className="small-print max-line-length">
              Zahlungen werden mit{" "}
              <a href="http://www.stripe.com" target="blank">
                Stripe
              </a>{" "}
              abgewickelt. Ich habe keinen Zugriff auf eure Zahlungsdetails.
            </p>
          </div>
          <div className="footer__shipping">
            <p className="small-print max-line-length">
              Versand per DHL nur innerhalb Deutschlands.
              <br />
              Versand ab {MIN_CART_VALUE}€ Einkaufswert.
              Versand inklusive.
            </p>
          </div>

          <ol className="footer__list">
            <li className="footer__item">
              <Link to="/mhd">Sorte herausfinden</Link>
            </li>
            <li className="footer__item">
              <Link to="/impressum">Impressum</Link>
            </li>
            <li className="footer__item">
              <Link to="/datenschutz">Datenschutz</Link>
            </li>
          </ol>
        </PageWidth>
      </Section>
    </div>
  );
};

export default Footer;

import React from "react";
import PropTypes from "prop-types";
import "./Input.less";

const Input = ({ className = "", outline = false, ...rest }) => {
  const classNames = ["input", className];
  if (outline) {
    classNames.push("input--outline");
  }

  return <input className={classNames.join(" ")} {...rest} />;
};

Input.protoTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  outline: PropTypes.bool
};

export default Input;
